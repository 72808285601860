import React from "react";
import Footer from "src/components/Footer_pt";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div id="fb-root" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "(function(d, s, id) {\nvar js, fjs = d.getElementsByTagName(s)[0];\nif (d.getElementById(id)) return;\njs = d.createElement(s); js.id = id;\njs.src = \"//connect.facebook.net/pt_PT/sdk.js#xfbml=1&version=v2.6&appId=607297119325946\";\nfjs.parentNode.insertBefore(js, fjs);\n}(document, 'script', 'facebook-jssdk'));\n"
          }}
        />
        <div id="disclosureText">
        </div>
        <div id="page" className="pageProducts">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/pt/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="nav-path-selected first has-sub-nav">
                    <a className="es_header" href="/pt/nossos-produtos/" target="_self">
                      Produtos
                    </a>
                    <ul>
                      <li>
                        <a href="/pt/nossos-produtos/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      {/* <li>
                        <a href="/pt/nossos-produtos/active-protect/">
                          Active &amp; Protect
                        </a>
                      </li> */}
                      <li>
                        <a href="/pt/nossos-produtos/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      {/* <li>
                        <a href="/pt/nossos-produtos/instantglow/">
                          Instant Glow
                        </a>
                      </li> */}
                      <li>
                        <a href="/pt/nossos-produtos/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/aftersun/">After sun</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/todos_os_Produtos/">
                          Todos os Produtos
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/pt/a_nossa_historia/" target="_self">
                      A Nossa História
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/pt/sobre_o_sol/" target="_self">
                      Aprenda Sobre o Sol
                    </a>
                  </li>
                  {/*<li>
                    <a className="es_header" href="/pt/Por-Onde-Andaste/" target="_self">
                      Por Onde Andaste 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      PAÍSES
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/our-products/allergy/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/our-products/allergy/">UK</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/allergy/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/allergy/">PT</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/allergy/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="product-hero">
              <div id="bgImage">
                <div className="areaInner">
                  <img
                    border="0"
                    className="ccm-image-block"
                    alt
                    src="/assets/images/Banner-Allergy-Homepage.png"
                    data-mobile="https://www.pizbuin.com/assets/images/Banner_Mobile_Allergy.png"
                  />
                </div>
              </div>
            </section>
            <section id="content">
              <div className="products-page innerContent clearfix">
                <div className="products-page__intro">
                  <div className="container">
                    <h1>
                      PIZ BUIN<sup>®</sup> ALLERGY
                    </h1>
                    <div className="products-page__intro__description">
                      <h3>Proteção da pele sensível ao sol</h3>
                      <p>
                        Em pessoas com pele sensível ao sol, mesmo uma exposição
                        mínima aos raios UV pode causar sensações de formigueiro
                        e manchas na pele, que, por sua vez, torna difícil
                        desfrutar do sol. A gama PIZ BUIN<sup>®</sup> ALLERGY é
                        especialmente desenvolvida com dermatologistas para
                        proteger a pele sensível ao sol. Combina filtros de
                        proteção solar UVA/UVB de ação imediata e eficaz com
                        CALMANELLE<sup>®</sup>, um complexo protetor e
                        anti-irritante único*, com resultados comprovados no
                        aumento da resistência da pele ao sol. CALMANELLE
                        <sup>®</sup> é formulado com um importante agente
                        antioxidante denominado FEVERFEW PFE<sup>™</sup> que
                        ajuda a aumentar a tolerância das células da pele ao
                        sol*. PIZ BUIN<sup>®</sup> ALLERGY, desfrute do sol sem
                        preocupações.
                      </p>
                      <p className="footNote">*Teste in vitro</p>
                      <p> </p>
                    </div>
                  </div>
                </div>
                <div className="products-page__list container clearfix">
                  <div className="products-page__product clearfix">
                    <a name="lo-o-allergy-pele-sens-vel-ao-sol" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/2019-allergy-sun-sensitive-skin-lotion-50spf-200ml.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>LOÇÃO ALLERGY PELE SENSÍVEL AO SOL</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>BENEFÍCIOS</h4>
                        <p>
                          A loção Allergy é especialmente desenvolvida com
                          dermatologistas para proteger a pele sensível ao sol,
                          é de rápida absorção e proporciona várias horas de
                          hidratação. Suaviza a pele sensível ao sol e ajuda a
                          prevenir a descamação da mesma.
                          <br />A Loção ALLERGY Pele Sensível ao Sol da PIZ BUIN
                          <sup>®</sup> é resistente à água e à transpiração.
                        </p>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>DISPONÍVEL NOS FATORES DE PROTEÇÃO SOLAR</h4>
                        <span className="factor factor15" />
                        <span className="factor factor30" />
                        <span className="factor factor50" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>RECOMENDAÇÃO DE USO</h4>
                        <p>
                          Aplicar em doses generosas e uniformemente antes da
                          exposição ao sol. A aplicação de uma dose reduzida
                          reduz significativamente o nível de proteção.
                          Reaplique frequentemente, especialmente depois de
                          transpirar, nadar ou secar-se com uma toalha. Evite a
                          exposição solar ao meio-dia e ao longo de muitas
                          horas, mesmo quando usa proteção solar. Manter bebés e
                          crianças afastados da exposição direta ao sol.
                        </p>
                        <h2>Disponível nos formatos</h2>
                        <p>200 ml</p>
                        <p>400 ml</p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTES E TECNOLOGIAS{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                SISTEMA DE FILTROS SOLARES AVANÇADOS UVA/UVB
                                <div className="textBlock bottom">
                                  <p>
                                    Como especialistas em proteção solar,
                                    utilizamos tecnologias inovadoras para nos
                                    certificarmos que os nossos clientes atingem
                                    um equilíbrio ótimo de cor e proteção sempre
                                    que desfrutam do sol.
                                  </p>
                                  <p>
                                    Cada sistema de filtros solares avançados
                                    UVA/UVB desenvolvido por PIZ BUIN
                                    <sup>®</sup> é uma poderosa tecnologia de
                                    proteção solar. Oferecem fotoestabilidade
                                    eficaz e um amplo espetro de proteção UVA e
                                    UVB, colocando os protetores solares PIZ
                                    BUIN<sup>®</sup> em conformidade com as mais
                                    recentes normas Europeias em produtos
                                    solares*.
                                  </p>
                                  <p>
                                    Todos os sistemas de filtros solares
                                    avançados UVA/UVB ajudam a proteger das
                                    radiações UVA e UVB.
                                  </p>
                                  <p>
                                    Raios UVB - prejudicam a superfície da pele
                                    e são a principal causa de queimaduras
                                    solares.
                                  </p>
                                  <p>
                                    Raios UVA - penetram mais profundamente e
                                    danificam o colagénio e a elastina, o que
                                    pode resultar em envelhecimento prematuro da
                                    pele e cancro.
                                  </p>
                                  <p>
                                    O sistema de filtros solares avançados
                                    UVA/UVB contém filtros UVA e UVB que ajudam
                                    a proteger do sol e dos danos a longo prazo
                                    na pele enquanto protegem dos efeitos mais
                                    graves da radiação UVA. Estes filtros cobrem
                                    a maior parte do espetro de radiação
                                    UVA/UVB, oferecendo uma proteção mais ampla
                                    à sua pele.
                                  </p>
                                  <p>
                                    <span className="footNote">
                                      *Recomendação da Comissão Europeia
                                    </span>
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d9ecfac1ba5e16cd4dcd7d6f49dc467c_f49.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                FEVERFEW PFE<sup>™</sup>
                                <div className="textBlock bottom">
                                  <p>
                                    A planta, Feverfew, tem origem nas montanhas
                                    e solos rochosos da Península Balcânica mas,
                                    hoje em dia, já pode ser encontrada em
                                    várias partes do mundo. A Kenvue, empresa detentora de PIZ BUIN
                                    <sup>®</sup>, patenteou este extrato
                                    inovador no uso dos cuidados da pele,
                                    aplicando um novo processo de extração que
                                    mantém os princípios ativos e elimina
                                    componentes indesejáveis ​​que podem causar
                                    alergias.
                                  </p>
                                  <p>
                                    Como é que o extrato Feverfew PFE
                                    <sup>™</sup> ajuda a sua pele?
                                  </p>
                                  <p>
                                    O extrato da planta Feverfew PFE<sup>™</sup>{" "}
                                    é um ingrediente suavizante e antioxidante
                                    altamente eficaz. Estudos <em>in vitro</em>{" "}
                                    provam que tem um efeito estimulador na
                                    atividade antioxidante maior do que qualquer
                                    outro extrato, incluindo extratos de sálvia,
                                    chá verde, chá preto, vitamina C e vitamina
                                    E. Ajuda a aliviar a vermelhidão da pele,
                                    repara as células da pele danificadas pelos
                                    raios UV e fortalece a resistência das
                                    células da pele ao sol*. A sua eficácia tem
                                    sido comprovada ao longo de vários anos de
                                    extensa pesquisa, realizada pela empresa
                                    detentora de PIZ BUIN<sup>®</sup>, Johnson
                                    &amp; Johnson, com diversas patentes
                                    abrangendo a sua aplicação no cuidado da
                                    pele e na área da cosmética.
                                  </p>
                                  <p className="footNote">
                                    Fonte: Derm Conversation Feb 2004
                                    <br />
                                    *Testado in vitro
                                  </p>
                                  <p> </p>
                                  <p>
                                    Fig. 1<br />
                                    Feverfew PFE<sup>™</sup> ajuda a proteger
                                    contra as alterações celulares, diminui o
                                    eritema induzido por radiações UVB e ajuda a
                                    reparar as células da pele danificadas pela
                                    radiação UV.
                                    <br />
                                    Melhora até 60% o eritema e a vermelhidão
                                    induzidos pela radiação UVB.
                                    <span className="footNote">
                                      <br />
                                      Fonte: Publicação Kenvue no
                                      Arch Dermatol Res Feb 2008; 300 (2) 69-80,
                                      Estudo aleatorizado, controlado por
                                      placebo, realizado em regime de dupla
                                      ocultação em 12 voluntários.
                                    </span>
                                  </p>
                                  <p>
                                    Fig. 2<br />
                                    Feverfew PFE<sup>™</sup> ajuda a proteger
                                    contra queimaduras solares, estimulando os
                                    processos naturais que ajudam a manter a
                                    integridade das células da pele * e a
                                    reforçar a sua capacidade de resistência ao
                                    sol.
                                    <span className="footNote">
                                      <br />
                                      Fonte: Kenvue R &amp; D,
                                      Método: aplicação de radiações UV sobre a
                                      pele humana com extrato de planta e
                                      avaliação da queimadura solar nas células.
                                    </span>
                                    <br />
                                    <span className="footNote">
                                      *Testado in vitro
                                    </span>
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/39ef095da165482afdc673791b8d83e8_f42.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                CALMANELLE<sup>®</sup>
                                <div className="textBlock bottom">
                                  <p>
                                    Calmanelle<sup>®</sup>, um complexo de
                                    proteção único com resultados comprovados no
                                    aumento da resistência da pele ao sol*.
                                    Calmanelle<sup>®</sup> combina um poderoso
                                    agente antioxidante denominado FEVERFEW PFE
                                    <sup>™</sup>, que ajuda a aumentar a
                                    tolerância das células da pele ao sol*, com
                                    um agente suavizante desenvolvido
                                    especialmente para a pele sensível e
                                    fragilizada.
                                  </p>
                                  <p className="footNote">*Teste in vitro</p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/e267aaf68572401af5c80a8bee7a29e7_f38.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* <div className="products-page__product clearfix">
                    <a name="spray-allergy-pele-sens-vel-ao-sol" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/2019-allergy-sun-sensitive-skin-spray-50spf-200ml.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>SPRAY ALLERGY PELE SENSÍVEL AO SOL</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>BENEFÍCIOS</h4>
                        <p>
                          O spray Allergy é especialmente desenvolvido para a
                          pele sensível ao sol ajuda, é de rápida absorção e
                          proporciona várias horas de hidratação. Suaviza a pele
                          sensível ao sol e ajuda a prevenir a descamação da
                          mesma. O formato em spray é prático, permitindo uma
                          aplicação fácil e homogénea.
                        </p>
                        <div className="products-page__product__details__benefits__hidden">
                          <p>
                            O Spray Allergy Pele Sensível ao Sol da PIZ BUIN
                            <sup>®</sup> é resistente à água e à transpiração.
                          </p>
                        </div>
                        <button className="products-page__product__details__benefits__more">
                          Saiba Mais
                        </button>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>DISPONÍVEL NOS FATORES DE PROTEÇÃO SOLAR</h4>
                        <span className="factor factor15" />
                        <span className="factor factor30" />
                        <span className="factor factor50" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>RECOMENDAÇÃO DE USO</h4>
                        <p>
                          Aplicar em doses generosas e uniformemente antes da
                          exposição ao sol. A aplicação de uma dose reduzida
                          reduz significativamente o nível de proteção.
                          Reaplique frequentemente, especialmente depois de
                          transpirar, nadar ou secar-se com uma toalha. Evite a
                          exposição solar ao meio-dia e ao longo de muitas
                          horas, mesmo quando usa proteção solar. Manter bebés e
                          crianças afastados da exposição direta ao sol. Não
                          aplique o spray diretamente na face. Para uso na face
                          coloque nas mãos e depois aplique.
                        </p>
                        <h2>Disponível nos formatos</h2>
                        <p>
                          <em />
                          200 ml
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTES E TECNOLOGIAS{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                SISTEMA DE FILTROS SOLARES AVANÇADOS UVA/UVB
                                <div className="textBlock bottom">
                                  <p>
                                    Como especialistas em proteção solar,
                                    utilizamos tecnologias inovadoras para nos
                                    certificarmos que os nossos clientes atingem
                                    um equilíbrio ótimo de cor e proteção sempre
                                    que desfrutam do sol.
                                  </p>
                                  <p>
                                    Cada sistema de filtros solares avançados
                                    UVA/UVB desenvolvido por PIZ BUIN
                                    <sup>®</sup> é uma poderosa tecnologia de
                                    proteção solar. Oferecem fotoestabilidade
                                    eficaz e um amplo espetro de proteção UVA e
                                    UVB, colocando os protetores solares PIZ
                                    BUIN<sup>®</sup> em conformidade com as mais
                                    recentes normas Europeias em produtos
                                    solares*.
                                  </p>
                                  <p>
                                    Todos os sistemas de filtros solares
                                    avançados UVA/UVB ajudam a proteger das
                                    radiações UVA e UVB.
                                  </p>
                                  <p>
                                    Raios UVB - prejudicam a superfície da pele
                                    e são a principal causa de queimaduras
                                    solares.
                                  </p>
                                  <p>
                                    Raios UVA - penetram mais profundamente e
                                    danificam o colagénio e a elastina, o que
                                    pode resultar em envelhecimento prematuro da
                                    pele e cancro.
                                  </p>
                                  <p>
                                    O sistema de filtros solares avançados
                                    UVA/UVB contém filtros UVA e UVB que ajudam
                                    a proteger do sol e dos danos a longo prazo
                                    na pele enquanto protegem dos efeitos mais
                                    graves da radiação UVA. Estes filtros cobrem
                                    a maior parte do espetro de radiação
                                    UVA/UVB, oferecendo uma proteção mais ampla
                                    à sua pele.
                                  </p>
                                  <p>
                                    <span className="footNote">
                                      *Recomendação da Comissão Europeia
                                    </span>
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d9ecfac1ba5e16cd4dcd7d6f49dc467c_f49.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                FEVERFEW PFE<sup>™</sup>
                                <div className="textBlock bottom">
                                  <p>
                                    A planta, Feverfew, tem origem nas montanhas
                                    e solos rochosos da Península Balcânica mas,
                                    hoje em dia, já pode ser encontrada em
                                    várias partes do mundo. A Kenvue, empresa detentora de PIZ BUIN
                                    <sup>®</sup>, patenteou este extrato
                                    inovador no uso dos cuidados da pele,
                                    aplicando um novo processo de extração que
                                    mantém os princípios ativos e elimina
                                    componentes indesejáveis ​​que podem causar
                                    alergias.
                                  </p>
                                  <p>
                                    Como é que o extrato Feverfew PFE
                                    <sup>™</sup> ajuda a sua pele?
                                  </p>
                                  <p>
                                    O extrato da planta Feverfew PFE<sup>™</sup>{" "}
                                    é um ingrediente suavizante e antioxidante
                                    altamente eficaz. Estudos <em>in vitro</em>{" "}
                                    provam que tem um efeito estimulador na
                                    atividade antioxidante maior do que qualquer
                                    outro extrato, incluindo extratos de sálvia,
                                    chá verde, chá preto, vitamina C e vitamina
                                    E. Ajuda a aliviar a vermelhidão da pele,
                                    repara as células da pele danificadas pelos
                                    raios UV e fortalece a resistência das
                                    células da pele ao sol*. A sua eficácia tem
                                    sido comprovada ao longo de vários anos de
                                    extensa pesquisa, realizada pela empresa
                                    detentora de PIZ BUIN<sup>®</sup>, Johnson
                                    &amp; Johnson, com diversas patentes
                                    abrangendo a sua aplicação no cuidado da
                                    pele e na área da cosmética.
                                  </p>
                                  <p className="footNote">
                                    Fonte: Derm Conversation Feb 2004
                                    <br />
                                    *Testado in vitro
                                  </p>
                                  <p> </p>
                                  <p>
                                    Fig. 1<br />
                                    Feverfew PFE<sup>™</sup> ajuda a proteger
                                    contra as alterações celulares, diminui o
                                    eritema induzido por radiações UVB e ajuda a
                                    reparar as células da pele danificadas pela
                                    radiação UV.
                                    <br />
                                    Melhora até 60% o eritema e a vermelhidão
                                    induzidos pela radiação UVB.
                                    <span className="footNote">
                                      <br />
                                      Fonte: Publicação Kenvue no
                                      Arch Dermatol Res Feb 2008; 300 (2) 69-80,
                                      Estudo aleatorizado, controlado por
                                      placebo, realizado em regime de dupla
                                      ocultação em 12 voluntários.
                                    </span>
                                  </p>
                                  <p>
                                    Fig. 2<br />
                                    Feverfew PFE<sup>™</sup> ajuda a proteger
                                    contra queimaduras solares, estimulando os
                                    processos naturais que ajudam a manter a
                                    integridade das células da pele * e a
                                    reforçar a sua capacidade de resistência ao
                                    sol.
                                    <span className="footNote">
                                      <br />
                                      Fonte: Kenvue R &amp; D,
                                      Método: aplicação de radiações UV sobre a
                                      pele humana com extrato de planta e
                                      avaliação da queimadura solar nas células.
                                    </span>
                                    <br />
                                    <span className="footNote">
                                      *Testado in vitro
                                    </span>
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/39ef095da165482afdc673791b8d83e8_f42.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                CALMANELLE<sup>®</sup>
                                <div className="textBlock bottom">
                                  <p>
                                    Calmanelle<sup>®</sup>, um complexo de
                                    proteção único com resultados comprovados no
                                    aumento da resistência da pele ao sol*.
                                    Calmanelle<sup>®</sup> combina um poderoso
                                    agente antioxidante denominado FEVERFEW PFE
                                    <sup>™</sup>, que ajuda a aumentar a
                                    tolerância das células da pele ao sol*, com
                                    um agente suavizante desenvolvido
                                    especialmente para a pele sensível e
                                    fragilizada.
                                  </p>
                                  <p className="footNote">*Teste in vitro</p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/e267aaf68572401af5c80a8bee7a29e7_f38.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div> */}
                  <div className="products-page__product clearfix">
                    <a name="creme-facial-allergy-pele-sens-vel-ao-sol" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/hydroinfusion-50.png" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>CREME FACIAL ALLERGY PELE SENSÍVEL AO SOL</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>BENEFÍCIOS</h4>
                        <p>
                          O rosto é a parte do corpo que está mais exposta ao
                          sol durante o dia. A pele sensível ao sol precisa, em
                          particular, de uma proteção especial. O Creme Facial
                          ALLERGY da PIZ BUIN<sup>®</sup> foi especialmente
                          desenvolvido para a pele sensível ao sol. Absorve
                          rapidamente e proporciona várias horas de hidratação
                          para manter macia e suave a pele delicada do rosto.
                          <br />O Creme Faciel Allergy Pele Sensível ao Sol da
                          PIZ BUIN<sup>®</sup> é resistente à água e à
                          transpiração.
                        </p>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>DISPONÍVEL NOS FATORES DE PROTEÇÃO SOLAR</h4>
                         <span className="factor factor50" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>RECOMENDAÇÃO DE USO</h4>
                        <p>
                          Aplicar em doses generosas e uniformemente antes da
                          exposição ao sol. A aplicação de uma dose reduzida
                          reduz significativamente o nível de proteção.
                          Reaplique frequentemente, especialmente depois de
                          transpirar, nadar ou secar-se com uma toalha. Evite a
                          exposição solar ao meio-dia e ao longo de muitas
                          horas, mesmo quando usa proteção solar. Manter bebés e
                          crianças afastados da exposição direta ao sol.
                        </p>
                        <h2>Disponível nos formatos</h2>
                        <p>
                          <em />
                          50 ml
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTES E TECNOLOGIAS{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                SISTEMA DE FILTROS SOLARES AVANÇADOS UVA/UVB
                                <div className="textBlock bottom">
                                  <p>
                                    Como especialistas em proteção solar,
                                    utilizamos tecnologias inovadoras para nos
                                    certificarmos que os nossos clientes atingem
                                    um equilíbrio ótimo de cor e proteção sempre
                                    que desfrutam do sol.
                                  </p>
                                  <p>
                                    Cada sistema de filtros solares avançados
                                    UVA/UVB desenvolvido por PIZ BUIN
                                    <sup>®</sup> é uma poderosa tecnologia de
                                    proteção solar. Oferecem fotoestabilidade
                                    eficaz e um amplo espetro de proteção UVA e
                                    UVB, colocando os protetores solares PIZ
                                    BUIN<sup>®</sup> em conformidade com as mais
                                    recentes normas Europeias em produtos
                                    solares*.
                                  </p>
                                  <p>
                                    Todos os sistemas de filtros solares
                                    avançados UVA/UVB ajudam a proteger das
                                    radiações UVA e UVB.
                                  </p>
                                  <p>
                                    Raios UVB - prejudicam a superfície da pele
                                    e são a principal causa de queimaduras
                                    solares.
                                  </p>
                                  <p>
                                    Raios UVA - penetram mais profundamente e
                                    danificam o colagénio e a elastina, o que
                                    pode resultar em envelhecimento prematuro da
                                    pele e cancro.
                                  </p>
                                  <p>
                                    O sistema de filtros solares avançados
                                    UVA/UVB contém filtros UVA e UVB que ajudam
                                    a proteger do sol e dos danos a longo prazo
                                    na pele enquanto protegem dos efeitos mais
                                    graves da radiação UVA. Estes filtros cobrem
                                    a maior parte do espetro de radiação
                                    UVA/UVB, oferecendo uma proteção mais ampla
                                    à sua pele.
                                  </p>
                                  <p>
                                    <span className="footNote">
                                      *Recomendação da Comissão Europeia
                                    </span>
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d9ecfac1ba5e16cd4dcd7d6f49dc467c_f49.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                FEVERFEW PFE<sup>™</sup>
                                <div className="textBlock bottom">
                                  <p>
                                    A planta, Feverfew, tem origem nas montanhas
                                    e solos rochosos da Península Balcânica mas,
                                    hoje em dia, já pode ser encontrada em
                                    várias partes do mundo. A Kenvue, empresa detentora de PIZ BUIN
                                    <sup>®</sup>, patenteou este extrato
                                    inovador no uso dos cuidados da pele,
                                    aplicando um novo processo de extração que
                                    mantém os princípios ativos e elimina
                                    componentes indesejáveis ​​que podem causar
                                    alergias.
                                  </p>
                                  <p>
                                    Como é que o extrato Feverfew PFE
                                    <sup>™</sup> ajuda a sua pele?
                                  </p>
                                  <p>
                                    O extrato da planta Feverfew PFE<sup>™</sup>{" "}
                                    é um ingrediente suavizante e antioxidante
                                    altamente eficaz. Estudos <em>in vitro</em>{" "}
                                    provam que tem um efeito estimulador na
                                    atividade antioxidante maior do que qualquer
                                    outro extrato, incluindo extratos de sálvia,
                                    chá verde, chá preto, vitamina C e vitamina
                                    E. Ajuda a aliviar a vermelhidão da pele,
                                    repara as células da pele danificadas pelos
                                    raios UV e fortalece a resistência das
                                    células da pele ao sol*. A sua eficácia tem
                                    sido comprovada ao longo de vários anos de
                                    extensa pesquisa, realizada pela empresa
                                    detentora de PIZ BUIN<sup>®</sup>, Johnson
                                    &amp; Johnson, com diversas patentes
                                    abrangendo a sua aplicação no cuidado da
                                    pele e na área da cosmética.
                                  </p>
                                  <p className="footNote">
                                    Fonte: Derm Conversation Feb 2004
                                    <br />
                                    *Testado in vitro
                                  </p>
                                  <p> </p>
                                  <p>
                                    Fig. 1<br />
                                    Feverfew PFE<sup>™</sup> ajuda a proteger
                                    contra as alterações celulares, diminui o
                                    eritema induzido por radiações UVB e ajuda a
                                    reparar as células da pele danificadas pela
                                    radiação UV.
                                    <br />
                                    Melhora até 60% o eritema e a vermelhidão
                                    induzidos pela radiação UVB.
                                    <span className="footNote">
                                      <br />
                                      Fonte: Publicação Kenvue no
                                      Arch Dermatol Res Feb 2008; 300 (2) 69-80,
                                      Estudo aleatorizado, controlado por
                                      placebo, realizado em regime de dupla
                                      ocultação em 12 voluntários.
                                    </span>
                                  </p>
                                  <p>
                                    Fig. 2<br />
                                    Feverfew PFE<sup>™</sup> ajuda a proteger
                                    contra queimaduras solares, estimulando os
                                    processos naturais que ajudam a manter a
                                    integridade das células da pele * e a
                                    reforçar a sua capacidade de resistência ao
                                    sol.
                                    <span className="footNote">
                                      <br />
                                      Fonte: Kenvue R &amp; D,
                                      Método: aplicação de radiações UV sobre a
                                      pele humana com extrato de planta e
                                      avaliação da queimadura solar nas células.
                                    </span>
                                    <br />
                                    <span className="footNote">
                                      *Testado in vitro
                                    </span>
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/39ef095da165482afdc673791b8d83e8_f42.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                CALMANELLE<sup>®</sup>
                                <div className="textBlock bottom">
                                  <p>
                                    Calmanelle<sup>®</sup>, um complexo de
                                    proteção único com resultados comprovados no
                                    aumento da resistência da pele ao sol*.
                                    Calmanelle<sup>®</sup> combina um poderoso
                                    agente antioxidante denominado FEVERFEW PFE
                                    <sup>™</sup>, que ajuda a aumentar a
                                    tolerância das células da pele ao sol*, com
                                    um agente suavizante desenvolvido
                                    especialmente para a pele sensível e
                                    fragilizada.
                                  </p>
                                  <p className="footNote">*Teste in vitro</p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/e267aaf68572401af5c80a8bee7a29e7_f38.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <nav className="platform-links">
              <div className="container">
                <ul>
                  <li>
                    <a
                      className="first"
                      href="/pt/nossos-produtos/hydro-infusion/"
                      target="_self"
                    >
                      Hydro Infusion
                    </a>
                  </li>
                  {/* <li>
                    <a
                      href="/pt/nossos-produtos/active-protect/"
                      target="_self"
                    >
                      Active &amp; Protect
                    </a>
                  </li> */}
                  <li>
                    <a href="/pt/nossos-produtos/moisturising/" target="_self">
                      Moisturising
                    </a>
                  </li>
                  <li>
                    <a href="/pt/nossos-produtos/tan_protect/" target="_self">
                      Tan &amp; Protect
                    </a>
                  </li>
                  {/* <li>
                    <a href="/pt/nossos-produtos/instantglow/" target="_self">
                      Instant Glow
                    </a>
                  </li> */}
                  <li>
                    <a
                      className="platform-links__active nav-path-selected"
                      href="/pt/nossos-produtos/allergy/"
                      target="_self"
                    >
                      Allergy
                    </a>
                  </li>
                  <li>
                    <a href="/pt/nossos-produtos/aftersun/" target="_self">
                      After sun
                    </a>
                  </li>
                  <li>
                    <a
                      href="/pt/nossos-produtos/todos_os_Produtos/"
                      target="_self"
                    >
                      Todos os Produtos
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            <section id="social">
              <a
                href="https://www.facebook.com/PizBuinPortugal"
                target="_blank"
                className="fb"
              >
                &nbsp;
              </a>
              <a
                href="https://www.instagram.com/pizbuinportugal/"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
              <a
                href="https://www.youtube.com/channel/UCuRXMca5zj_v97YlGJip63A"
                target="_blank"
                className="yt"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function(){\n$('.products-page__product__details__benefits__more').on('click', function(){\nvar box = $(this).siblings('.products-page__product__details__benefits__hidden');\nif(box.is(':visible')){\nbox.slideUp();\n$(this).text('Saiba Mais');\n} else {\nbox.slideDown();\n$(this).text('Fechar');\n}\n});\n});\n"
          }}
        />
       <div className="clear">
          <br />
        </div>
      </div>
    );
  }
}

export default Page;
